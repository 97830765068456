// ナビゲーションボタン類（コンテンツの途中に入る系）

@import "../colors";

// ナビゲーションボタン（110x40 / 館トップ等）
.a-nav-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 4px;
    width: 110px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 4px;
    color: $base-color;
    text-align: center;
    background: #fff;
    border: 1px solid $border-color;
    box-shadow: 1px 1px 0 0 $border-color;

    .ico {
        font-size: 16px;

        // 各アイコンサイズ微調整
        // 新着・NEW
        &.ico-NewItems{
            font-size: 18px;
        }
    }

    .ico-txt,
    .button-text {
        font-size: 10px;
        font-weight: 700;
        line-height: 1;
    }
}
