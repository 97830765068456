@charset "utf-8";

// ============================
// 読み込み
// ============================
@import '../../templates/layout/mixin';
@import '../../templates/layout/font-variable';
@import '../../atoms/colors';
@import '../../atoms/z-index';
@import "../../atoms/card-texts";
@import "../../atoms/book-parts";
@import "../../molecules/thumbnail";
@import "../../molecules/btn-box";

// ============================
// レスポンシブタイル用MIXIN（書影のアスペクト比を保つ）
// ============================
// レスポンシブタイル用
// -----------------------------------------------------------------------------
// タイル（ベースのスタイル：下部で調整用MIXINを呼ぶ）
// $list-max-width: 外枠の最大サイズ（px）
// $column-num: カラム数（px）
// $grid-column-gap: グリッドの左右間隔（px）
// $grid-row-gap: グリッドの上下間隔（px）
// $tile-padding: タイル内部のPadding（px）
// $is-negative-margin: タイル内部のPadding分のネガティブマージンを指定する 1:左右 2:上下左右
// $thumbnail-size: 書影サムネイルのサイズ（％）
// $is-slider: 横スクロールのスライダーかどうか（スライダーだと書影の高さpxで固定、スライダーではないと書影の高さvwで可変）
@mixin responsiveTile($list-max-width: 1080, $column-num: 5, $grid-column-gap: 0, $grid-row-gap: 0, $tile-padding: 8, $is-negative-margin: 0, $thumbnail-size: 80, $screen-padding: 40, $is-slider: true) {
    .o-tile-list,
    .o-tile-list__slide {
        display: grid;
        -webkit-text-size-adjust: 100%;

        // ex/feature配下で崩れないようにするため
        *, *::before, *::after {
            box-sizing: border-box;
        }
    }

    .o-tile-list {
        justify-content: space-around;
    }

    // 横スクロールするタイプのタイルリスト（フォローリストより）----------
    .o-tile-list__slide {
        grid-auto-flow: column;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }

    // タイル1つ分
    .o-tile, %o-tile {
        display: flex;
        flex-direction: column;

        .o-tile-inner {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 100%;
            background: #fff;
            grid-auto-rows: min-content 1fr;
            justify-content: space-between;
            outline: 1px dotted transparent;
            word-break: break-all;
        }
    }

    // 購入済み・登録済み
    .o-tile--purchased, %o-tile--purchased {
        @extend %o-tile;

        .o-tile-inner {
            background: $purchased-color;
            outline: 1px dotted #fff;
        }
    }

    // 書影
    .o-tile-book-info {
        padding-bottom: 5px;

        @include pc {
            padding-bottom: 12px;
        }

        .o-tile-book-img {
            position: relative;
            text-align: center;
            margin: auto;

            .m-thumb-box {
                width: 100%;
                height: 100%;
                //@include centering(x);
                //top: 0;

                // 書影画像
                .a-thumb-img {
                    @include opacity-hover;

                    img {
                        display: inline-block;
                        max-width: 100%;
                        width: auto;
                        object-fit: contain;
                        vertical-align: bottom;
                    }
                }

                // 個別削除ボタンのタップ範囲・位置調整
                .a-close-btn-box {
                    padding: 0 0 26px 26px;
                    top: -4px;
                    right: -3px;
                    @include opacity-hover;
                }

                // チェックボタン位置・タップ範囲調整
                .a-thumb-box__check {
                    right: -6px;
                    bottom: -8px;
                    @include opacity-hover;
                }
            }
        }

        // ジャンルタグ
        .o-tile-tag-box {
            width: 100%;
            padding: 7px 0 2px;
            font-size: 0;

            @include pc {
                padding-bottom: 4px;
            }

            // タグ本体
            span {
                &:not(:last-child) {
                    margin-right: 4px;

                    @include pc {
                        margin-right: 3px;
                    }
                }
            }
        }

        // 作品タイトル
        .o-tile-ttl {
            a {
                font-size: 12px;
                font-weight: 700;
                line-height: 1.2;
                max-height: 2.75rem;
                white-space: normal;
                overflow: hidden;
                word-break: break-all;
                @include line-clamp(2);
                @include text-hover;

                @include pc {
                    height: 28px;
                }
            }
        }

        // 著者名
        .o-tile-book-author {
            color: $weak-gray;
            font-size: 10px;
            line-height: 1.2;
            padding: 3px 0 1px;

            @include pc {
                padding-top: 4px;
            }
        }
    }

    // タイル内下半分のエリア----------
    .o-tile-under-box {
        margin-top: auto;

        // 登録状況タグ
        .o-tile-status-tag {
            padding: 2px 0 1px 0;
            text-align: right;

            @include pc {
                padding-top: 4px;
            }
        }

        // 価格周り
        .o-tile-price {
            padding: 0 1px 1px 0;
            font-size: 10px;
            color: $strong-red;
            text-align: right;

            @include pc {
                padding-top: 4px;
            }

            // 金額
            .a-tile-price {
                font-size: 11px;
                font-weight: $font-bold;

                @include pc {
                    font-size: 13px;
                }
            }

            // テキストサイズ・色 微調整
            .a-tile-period {
                display: block;
            }

            .a-tile-schedule {
                display: block;
                color: $strong-blue;
            }

            .a-tile-price--each,
            .a-tile-price--yen {
                font-size: 10px;
                font-weight: normal;
            }

            .a-tile-price--range {
                color: $base-text-color;
                font-size: 10px;
                font-weight: normal;
            }

            .a-tile-price--tax {
                display: inline-block;
                color: $base-text-color;
                font-size: 10px;
                font-weight: normal;
            }

            // 予約コインUPタグがある場合
            .o-tile-price__info--coinup {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                @media (min-width: 1100px) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                }

                // 予約コインUPタグ
                .a-tag-reserve-coin-up {
                    margin-top: 2px;

                    @media (min-width: 1100px) {
                        order: -1;
                        flex-shrink: 0;
                        margin-top: 0;
                        margin-right: 4px;
                    }
                }
            }
        }

        // 初回半額還元コイン
        .o-tile-bonus-coin {
            font-size: 10px;
            margin-top: -2px;
            text-align: right;

            .a-bonus-coin-number {
                color: $strong-red;
                font-size: 11px;
                font-weight: $font-bold;

                @include pc {
                    font-size: 13px;
                }
            }
        }

        .o-tile-count {
            padding: 3px 0 1px;
            font-size: 10px;
            line-height: 1.2;
            text-align: right;
        }

        // ボタンブロック：可変対応
        .m-btn-box {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            grid-column-gap: 4px;
            width: 100%;
            min-height: 42px;
            padding: 2px 1px 1px;

            li {
                margin: 0;

                a {
                    width: 100%;
                    margin: 0;
                }
            }

            .a-purchase-AppleID-btn, .a-read-by-app-btn {
                margin: 0 2px 0 0;
            }
        }
    }

    // レスポンシブスライダー　swiper（カート画面の棚）----------
    .o-tile-list--slider {
        display: flex;

        .swiper-slide {
            &:not(:last-child) {
                @include pc {
                    margin-right: 8px;
                }
            }
        }

        .o-tile,
        .o-tile--purchased {
            width: 100%;
            height: 100%;
            box-sizing: content-box;
        }

        // スライダーボタン
        & + .a-slider-btn__prev {
            // SPでボタン非表示
            @include utab {
                display: none;
            }

            & + .a-slider-btn__next {
                // SPでボタン非表示
                @include utab {
                    display: none;
                }
            }
        }

        .swiper-slide {
            height: auto;
        }
    }

    // タイル調整
    @include responsiveAdjustTile($list-max-width: $list-max-width, $column-num: $column-num, $grid-column-gap: $grid-column-gap, $grid-row-gap: $grid-row-gap, $tile-padding: $tile-padding, $is-negative-margin: $is-negative-margin, $thumbnail-size: $thumbnail-size, $screen-padding: $screen-padding, $is-slider: $is-slider);
}

@mixin responsiveAdjustTile($list-max-width: 1080, $column-num: 5, $grid-column-gap: 0, $grid-row-gap: 0, $tile-padding: 8, $is-negative-margin: 0, $thumbnail-size: 80, $screen-padding: 40, $is-slider: true) {
    // ネガティブマージンを付ける場合、ネガティブマージン分を外枠の最大サイズに足す
    @if $is-negative-margin == 1 or $is-negative-margin == 2 {
        $list-max-width: $list-max-width + $tile-padding * 2;
    }
    // グリッドの横幅
    $grid-width: floor(($list-max-width - ($grid-column-gap * ($column-num - 1))) / $column-num);

    // アスペクト比
    $book-aspect: 1.43;

    $def-height :floor(($grid-width - $tile-padding * 2) * $book-aspect);

    // 購入済みのグレー背景分がブロックからはみ出る感じにネガティブマージンを指定する
    @if $is-negative-margin == 1 {
        margin-left: $tile-padding * -1px;
        margin-right: $tile-padding * -1px;
    } @else if $is-negative-margin == 2 {
        margin: $tile-padding * -1px;
    }

    // タイル1つ分
    .o-tile, %o-tile {
        width: $grid-width * 1px;

        .o-tile-inner {
            padding:  $tile-padding * 1px;
        }
    }

    .o-tile-list,
    .o-tile-list__slide {
        grid-template-columns: repeat(auto-fill, $grid-width * 1px);
        grid-column-gap: $grid-column-gap * 1px;
        grid-row-gap: $grid-row-gap * 1px;
        margin: 0;
    }

    .o-tile-book-info {
        .a-thumb-img {
            max-width: $thumbnail-size * 1%;
        }

        .o-tile-book-img {
            // 書影の高さ
            @if $is-slider == true {
                .m-thumb-box {
                   height: floor($def-height * $thumbnail-size / 100) * 1px;
                }

            } @else {
                .m-thumb-box {
                    height: (floor($def-height * $thumbnail-size / ($list-max-width + $screen-padding)) - 1) * 1vw;
                }
            }
        }
    }

    .a-thumb-img {
        max-width: $thumbnail-size * 1%;

        // 書影の高さ
        @if $is-slider == true {
            img {
                max-height: floor($def-height * $thumbnail-size / 100) * 1px;
            }

        } @else {
            img {
                max-height: (floor($def-height * $thumbnail-size / ($list-max-width + $screen-padding)) - 1) * 1vw;
            }
        }
    }
}

