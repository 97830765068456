// コンテンツのセクション内で使用する固有のボタン

@import "../colors";
@import "../z-index";
@import "../arrow-mixin";
@import "../../templates/layout/mixin";
@import "../../../pc/templates/layout/define";

// もっと見るボタン小（館トップ等で使用）
.a-more-btn,
%a-more-btn {
    display: block;
    position: relative;
    width: 100px;
    height: 28px;
    padding-left: 16px;
    border-radius: 25px;
    background-color: $base-color;
    font-size: 11px;
    color: #ffffff;
    line-height: 28px;
    @include hover;
    // 矢印右向き
    &::after {
        @include right-arrow(#ffffff);
        right: 10px;
        width: 10px;
        height: 10px;
    }
}

// もっと見るボタン小（開閉ボタン）
.a-more-btn--display-toggle {
    @extend %a-more-btn;

    color: $base-color;
    background-color: #ffffff;
    border: 1px solid $border-color;
    box-shadow: 0 1px 0 0 $border-color;


    // 矢印下向き
    &::after {
        margin-top: -2px;
        border-color: $weak-gray;
        transition-duration: 0.2s;
        @include centering(y, 45);
    }

    // テキストの表示切替
    > span:nth-of-type(2){
        display: none;
    }

    // 開いていいるとき
    &.on{
        // テキストの表示切替
        > span:nth-of-type(1){
            display: none;
        }
        > span:nth-of-type(2){
            display: inline;
        }

        // 矢印上向き
        &::after {
            margin-top: 2px;
            @include centering(y, 225);
        }
    }
}

// すべて見るボタン
.a-more-btn--all {
    @extend %a-more-btn;

    padding-left: 0;
    width: auto;
    color: $base-color;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid $border-color;
    box-shadow: 0 1px 0 0 $border-color;
    @include hover;

    &::after {
        border-color: $weak-gray;
    }
}

// 続きを読むボタン
.a-read-next-button {
    @extend %a-more-btn;
    color: $base-link-color;
    font-weight: bold;
    background-color: #fff;
    border: 1px solid $border-color;

    &::after {
        border-color: $weak-gray;
    }
}