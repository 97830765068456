@charset "utf-8";
@import "../../atoms/z-index";
@import "../../atoms/colors";

// タイトルを全表示（タイトルオーバーレイ表示）用

.o-tile-overlay, .o-tile-overlay--on {
    transition: all .2s;
}

// 「タイトルを全表示」OFF時の設定
.o-tile-overlay {
    position: absolute;
    left: 1px;
    right: 1px;
    top: 0;
    margin-top: 170%;
    z-index: $tile-overlay-z-index;
    height: 40%;
    background: $base-color;
    opacity: 0;
    visibility: hidden;

    .o-tile-overlay-ttl {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 10;
        height: 100%;
        width: 100%;
        padding: 8px;
        word-break: break-all;
        font-size: 10px;
        font-weight: 700;
        line-height: 15px;
        color: #fff;

        // iOSでline-clampの表示対策（何かで囲っていないと3点リーダが中途半端な位置に出る）
        :first-child {
            &::before {
                content: ""
            }

            &::after {
                content: "";
            }
        }
    }

    overflow: hidden;
}

// 「タイトルを全表示」ON時の設定
.o-tile-overlay--on {
    @extend .o-tile-overlay;
    height: calc(100% - 115px);
    margin-top: 53%;
    opacity: 0.95;
    visibility: visible;
}
