/**
  初心者向けバナー（SP）
 */
.o-beginner-banner {
    text-align: center;
    margin-bottom: 8px;

    #btn-banner-beginner {
        a {
            display: block;
        }

        img {
            max-width: 100%;
            height: auto;
            vertical-align: middle;
        }
    }
}