@charset "utf-8";

// 館トップ

// ============================
// 読み込み
// ============================
@import "../../atoms/buttons/section-btn";
@import "../../atoms/buttons/nav-btn";
@import "../../molecules/popular-book-list";
@import "../../molecules/keyword-list";
@import "../../molecules/personalise-modal";
@import "../../organisms/tile/tile-detail";
@import "../../organisms/section/contents-section";
@import "../../organisms/top-banner/top-campaign-banner";
@import "../../organisms/top-banner/beginner-banner";
@import "../../organisms/top-banner/store-top-banner";
@import "../../organisms/block-link-list";

@import "../../../../../../asset/bob/scss/sp/module/common/coupon-nav";

// コンテンツエリア大枠
.p-content-wrap {
    padding-bottom: 16px;
}

// お知らせ
#js-slidetoggle--info-list {
    display: none;
}

// コンテンツの途中に入るボタンブロック
.p-contents-btn-box--nav {
    padding-bottom: 16px;
}

// ナビゲーションボタンのリスト
.p-nav-btn-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &--bottom {
        margin-top: 10px;
    }
}

.o-tile-wrap--3column .o-tile-book-info .o-tile-ttl {
    -webkit-line-clamp: 2;
}

.o-tile-wrap--shelf .o-tile-book-info .o-tile-ttl {
    -webkit-line-clamp: 2;
}

.o-freebook-series {
    color: #e40007;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    margin-top: 5px;
    min-height: 17px;

    .ico {
        color: #1d4a6d;
    }
}

.m-btn-box {
    li {
        float: unset !important;
    }

    .a-free-btn {
        width: 100%;

        .btn-txt {
            font-size: 1.4rem;
            font-weight: bold;
            margin-top: 8px;
            display: block;
        }
    }
}


.p-content-wrap .o-contents-section.p-section-shelf {
    // 背景白を無効化
    background-color: inherit;
    // 上下の線を無効化
    border-top: inherit;
    padding: unset;

    .m-guide-bnr-link img {
        max-width: 100%;
        height: auto;
    }

    // 書影などが入る背景白い部分。
    .o-contents-section-inner-section {
        border-top: 2px solid #1d3850;
        background-color: #fff;
        padding: 16px 16px;
    }

    // もっと見る
    .a-more-btn {
        color: $base-link-color;
        width: inherit;
        background-color: #fff;
        margin: 16px 10px;
        padding-left: unset;
        border: 1px solid #ccc;
        box-shadow: 0 1px 0 0 #ccc;
        font-size: 14px;
        font-weight: bold;
        text-align: center;

        // 文字をを小さくして枠内に入るようにしておく
        @media (max-width: ($se)) {
            font-size: 12px;
        }

        // 矢印
        &::after {
            @include right-arrow($weak-gray);
            right: 10px;
            width: 10px;
            height: 10px;
        }
    }

    // バナー部分
    .m-contents-section-bnr {
        text-align: center;
        max-width: 300px;
        margin: 0 auto 20px;

        .m-guide-bnr-link {
            display: inline-block;

            & + .m-guide-bnr-link {
                margin-top: 10px;
            }
        }

        img {
            vertical-align: middle;
        }
    }
}

// ranking枠に入るボタンブロック
.p-contents-btn-box {
    padding: 0 10px 16px;

    .a-more-btn--all {
        font-size: 14px;
        font-weight: 700;
    }
}

// まずはここから
.o-contents-section-beginner {
    .o-contents-section-ttl {
        border-left: unset;
        text-align: center;
        padding: 0;
    }

    .o-contents-section-body {
        .top-beginnerWrap {
            display: flex;
            justify-content: center;

            .top-btn {
                width: calc(50% - 5px);
            }

            // 既存スタイルの上書き
            .a-basic-btn--beginner {
                margin: 0 10px 0 0;
            }

            // 既存スタイルの上書き
            .a-basic-btn--member-registration {
                margin: 0;
            }
        }
    }
}

// MA埋め込み
.o-ma-embed {
    margin-bottom: 16px;
}
