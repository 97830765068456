@charset "UTF-8";
@import "../atoms/z-index";

// 書影周り
.m-thumb-box {
    display: inline-block;

    // \0アイコンサイズ調節
    .a-label-free {
        background-size: 28px 28px;
        width: 28px;
        height: 28px;
        top: -4px;
        left: -4px;
    }

    // SALEアイコンサイズ調節
    .a-label-sale {
        background-size: 27px 27px;
        width: 27px;
        height: 27px;
        top: -4px;
        left: -3px;
    }

    // アイコンサイズ調節
    %thumbnail_a-label_base {
        background-size: 34px 34px;
        width: 34px;
        height: 34px;
        right: -4px;
    }

    // newアイコンサイズ調節
    .a-label-new {
        @extend %thumbnail_a-label_base;
    }

    // 予約アイコンサイズ調節
    .a-label-reserve {
        @extend %thumbnail_a-label_base;
    }

    // セット商品サイズ調節
    .a-label-set {
        @extend %thumbnail_a-label_base;
    }

    // 試し読み増アイコン-リボンの下
    .a-label-inc-trial {
        background-size: 26px 26px;
        width: 26px;
        height: 26px;
    }

    // コインアップ1・2・3size調節
    .a-label-coin-up-1 {
        background-size: 14px 39px;
        width: 14px;
        height: 39px;
    }

    .a-label-coin-up-2 {
        background-size: 14px 48px;
        width: 14px;
        height: 48px;
    }

    .a-label-coin-up-3 {
        background-size: 14px 57px;
        width: 14px;
        height: 57px;
    }

    .a-thumb-img {
        position: relative;

        .a-ttsk-label-logo {
            position: absolute;
            background: url(/anne/img/ttsk/logo-ttsk.svg) no-repeat 0 0;
            background-size: 100% auto;
            z-index: $ttsk-label-z-index;
            bottom: 1px;
            left: 2px;
            width: 28%;
            padding-top: 25.638%;
        }
    }
}

