//館トップカルーセルの左右の矢印

//タテスクのbaseTransitionと同じ
@mixin baseTransition {
    transition: all 0.3s ease-in-out;
}

%swiper-button {
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;
    outline: none;
    width: 44px;
    height: 44px;
    z-index: 20;
}

%swiper-button__arrow {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    opacity: 0.9;
    background-color: #fff;
    background-image: none;
    border-radius: 50%;
    outline: none;
    z-index: 10;
    filter: drop-shadow(0 2px 2px rgba(30, 56, 80, 0.2));
    @include baseTransition;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-right: solid 2px #1e3850;
        border-top: solid 2px #1e3850;
        box-sizing: border-box;
    }
}

.a-swiper-button--next {
    @extend %swiper-button;
    right: 0;
}

.a-swiper-button--prev {
    @extend %swiper-button;
    left: 0;
}

.a-swiper-button__arrow--next {
    @extend %swiper-button__arrow;
    right: 5px;

    &::before {
        transform: translate(-70%, -50%) scaleX(0.9) rotate(45deg);
    }
}

.a-swiper-button__arrow--prev {
    @extend %swiper-button__arrow;
    left: 5px;

    &::before {
        transform: translate(-30%, -50%) scaleX(0.9) rotate(-135deg);
    }
}