@charset "utf-8";

// ============================
// 読み込み
// ============================
@import '../../templates/layout/mixin';
@import '../../templates/layout/font-variable';
@import '../../atoms/colors';
@import '../../atoms/z-index';
@import "../../atoms/card-texts";
@import "../../atoms/book-parts";
@import "../../molecules/thumbnail";
@import "../../molecules/btn-box";

// ============================
// MIXIN
// ============================

// Todo:閲覧日が入るのは閲覧履歴のみ。タイルを汎用として使うなら高さは汎用と閲覧履歴用で分ける？

// タイル（ベースのスタイル：下部で調整用MIXINを呼ぶ）
// $list-max-width: 外枠の最大サイズ（px）
// $column-num: カラム数（px）
// $grid-column-gap: グリッドの左右間隔（px）
// $grid-row-gap: グリッドの上下間隔（px）
// $tile-padding: タイル内部のPadding（px）
// $is-negative-margin: タイル内部のPadding分のネガティブマージンを指定する 1:左右 2:上下左右
// $thumbnail-size: 書影サムネイルのサイズ（％）
@mixin tile($list-max-width: 370, $column-num: 4, $grid-column-gap: 0, $grid-row-gap: 0, $tile-padding: 10, $is-negative-margin: 0, $thumbnail-size: 100, $is-thumb-padding-top: false) {
    .o-tile-list,
    .o-tile-list__slide {
        display: grid;
        margin: 0;
    }

    .o-tile-list {
        justify-content: space-around;
    }

    // 横スクロールするタイプのタイルリスト（フォローリストより）----------
    .o-tile-list__slide {
        grid-auto-flow: column;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }

    // タイル1つ分
    .o-tile, %o-tile {
        display: flex;

        .o-tile-inner {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            background: #fff;
            grid-auto-rows: min-content 1fr;
            justify-content: space-between;
            word-break: break-all;
        }
    }

    // 購入済み・登録済み
    .o-tile--purchased, %o-tile--purchased {
        @extend %o-tile;

        .o-tile-inner {
            background: $purchased-color;
            outline: 1px dotted #fff;
        }
    }

    .o-tile--history {
        @extend %o-tile;
    }

    .o-tile--history--purchased{
        @extend %o-tile--purchased;
    }

    // シリーズ表示時の高さ調整
    .o-tile--series,  %o-tile--series {
        @extend %o-tile;
        min-height: 230px;
        &.o-warensai-tile {
            min-height: initial;
        }
        //max-height: 305px;

        // モーダルを開くよう下向き矢印ボタン
        .o-tile-under-box .a-down-arrow-btn {
            display: block;
            position: relative;
            height: 18px;
            padding: 5px 0;

            &::after {
                content: "";
                display: block;
                position: absolute;
                right: 6px;
                width: 10px;
                height: 10px;
                margin-top: -5px;
                border-top: 2px solid $base-color;
                border-right: 2px solid $base-color;
                transform: rotate(135deg);
            }
        }
    }

    // タイトル全表示のオーバーレイが、単品用のでは長すぎるので短くする
    .o-tile--series .o-tile-overlay--on,
    .o-tile--series--purchased .o-tile-overlay--on {
        height: calc(100% - 70px);
    }

    // 購入済み・登録済み
    .o-tile--series--purchased {
        @extend %o-tile--series;

        .o-tile-inner {
            background: $purchased-color;
            outline: 1px dotted #fff;
        }
    }

    .o-tile__top-block{
        position: relative;
        margin-bottom: 10px;
        // 閲覧日
        .o-tile-browsed-date {
            font-size: 10px;
            color: #666;
            text-align: left;
            padding: 2px 0;
        }

        .a-close-btn-box{
            top: 0;
            right: 0;
            padding: 0 0 29px 29px;
            z-index: $delete-btn-z-index;
            .a-close-btn{
                width: 18px;
                height: 18px;
                .ico{
                    font-size: 16px;
                    line-height: 1;
                }
            }
        }
    }

    // 書影
    .o-tile-book-info {
        .o-tile-book-img {
            position: relative;
            font-size: 0;
            text-align: center;

            .m-thumb-box {
                img {
                    display: inline-block;
                    max-width: 100%;
                    width: auto;
                }

                // 個別削除ボタンのタップ範囲・位置調整
                .a-close-btn-box {
                    padding: 0 0 26px 26px;
                    top: -4px;
                    right: -3px;
                }

                // チェックボタン位置・タップ範囲調整
                .a-thumb-box__check {
                    right: -6px;
                    bottom: -8px;
                }
            }
        }

        // ジャンルタグ
        .o-tile-tag-box {
            width: 100%;
            padding: 7px 0 2px;
            font-size: 0;

            // タグ本体
            span {
                margin-right: 4px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        // 作品タイトル
        .o-tile-ttl {
            display: -webkit-box;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.2;
            max-height: 2.75rem;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
            overflow: hidden;
            text-overflow: clip;
            word-break: break-all;

            // iOSでline-clampの表示対策（何かで囲っていないと3点リーダが中途半端な位置に出る）
            :first-child {
                &::before {
                    content: ""
                }

                &::after {
                    content: "";
                }
            }
        }

        // 著者名
        .o-tile-book-author {
            padding: 3px 0 1px;
            font-size: 9px;
            line-height: 1.2;
            color: $base-text-color;
        }
    }

    // タイル内下半分のエリア----------
    .o-tile-under-box {

        // 登録状況タグ
        .o-tile-status-tag {
            padding: 2px 0 1px 0;
            text-align: right;
        }

        // 価格周り
        .o-tile-price {
            padding: 0 1px 1px 0;
            font-size: 11px;
            font-weight: $font-bold;
            color: $strong-red;
            text-align: right;

            // テキストサイズ・色 微調整
            .a-tile-period {
                display: block;
                font-size: 9px;
                font-weight: normal;
            }

            .a-tile-schedule {
                display: block;
                font-size: 9px;
                color: $strong-blue;
            }

            .a-tile-price--each {
                font-size: 9px;
                font-weight: normal;
            }

            .a-tile-price--yen {
                font-size: 9px;
                font-weight: normal;
            }

            .a-tile-price--range {
                font-size: 9px;
                color: $base-text-color;
            }

            .a-tile-price--tax {
                display: inline-block;
                font-size: 9px;
                font-weight: normal;
                color: $base-text-color;
            }
        }

        .o-tile-count {
            padding: 3px 0 1px;
            font-size: 9px;
            line-height: 1.2;
            text-align: right;
        }

        // ボタンブロック：可変対応
        .m-btn-box {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            grid-column-gap: 4px;
            width: 100%;
            min-height: 42px;
            padding: 1px 1px;

            li {
                margin: 0;
                a {
                    width: 100%;
                    margin: 0;
                }
            }

            .a-purchase-AppleID-btn, .a-read-by-app-btn {
                margin: 0 2px 0 0;
            }
        }
    }

    // シリーズ用タイル
    .o-series-tile-list {
        display: grid;
        margin: 0 0 8px 0;
        grid-auto-rows: auto;
        justify-content: center;
    }

    // シリーズ用タイル1個分
    .o-series-tile {
        position: relative;
        display: grid;

        // シリーズ一覧用モーダルが開いている時の矢印
        .o-tile--series--on {
            .o-tile-book-img {
                &::before, &::after {
                    content: "";
                    position: absolute;
                    z-index: $series-modal-arrow-z-index;
                    bottom: -5px;
                    right: 12%;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 11px 15px 11px;
                    border-color: transparent transparent $base-color transparent;
                }

                &::after {
                    margin: 0 0 -4px 0;
                    border-color: transparent transparent #fff transparent;
                }
            }
        }

        // シリーズ用のタイトル全表示ではモーダルの下に出したいので、z-indexを下げる
        // （NOTE: 全タイルで下げないといけないかも）
        .o-tile-overlay {
            z-index: $series-tile-overlay-z-index;
        }
    }

    // レスポンシブスライダー　swiper（カート画面の棚）----------
    .o-tile-list--slider {
        display: flex;

        .swiper-slide {
            &:not(:last-child) {
                @include pc {
                    margin-right: 8px;
                }
            }
        }

        .o-tile,
        .o-tile--purchased {
            width: 100%;
            height: 100%;
            box-sizing: content-box;

            .o-tile-book-info {
                // 書影画像
                .a-thumb-img {
                    @include opacity-hover;
                }

                // 書影チェックボタン
                .a-thumb-box__check {
                    @include opacity-hover;
                }


                // タイトル
                .o-tile-ttl {
                    @include pc {
                        height: 28px;
                    }

                    @include hover-pc {
                        text-decoration: underline;
                    }
                }

                // 著者
                .o-tile-book-author {
                    color: $weak-gray;
                    font-size: 10px;

                    @include pc {
                        padding-top: 4px;
                    }
                }

                // タグ
                .o-tile-tag-box {
                    @include pc {
                        padding-bottom: 4px;

                        span {
                            margin-right: 3px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .o-tile-under-box {
                // 値段表示
                .a-tile-price {
                    @include pc {
                        font-size: 13px;

                        .a-tile-period,
                        .a-tile-schedule,
                        .a-tile-price--each,
                        .a-tile-price--yen,
                        .a-tile-price--tax {
                            font-size: 10px;
                        }
                    }
                }

                // ボタン
                .m-btn-box {
                    a {
                        @include opacity-hover;
                    }
                }
            }
        }

        // スライダーボタン
        & + .a-slider-btn__prev {
            // SPでボタン非表示
            @include utab {
                display: none;
            }

            & + .a-slider-btn__next {
                // SPでボタン非表示
                @include utab {
                    display: none;
                }
            }
        }

        .swiper-slide {
            height: auto;
        }
    }

    // タイル調整
    @include adjustTile($list-max-width, $column-num, $grid-column-gap, $grid-row-gap, $tile-padding, $is-negative-margin, $thumbnail-size, $is-thumb-padding-top);
}

// -----------------------------------------------------------------------------

// タイル調整（ベースのスタイルに追加で可変部分を定義する / メディアクエリーでサイズを調整する場合はこれを単体で呼ぶ）
// $list-max-width: 外枠の最大サイズ（px）
// $column-num: カラム数（px）
// $grid-column-gap: グリッドの左右間隔（px）
// $grid-row-gap: グリッドの上下間隔（px）
// $tile-padding: タイル内部のPadding（px）
// $is-negative-margin: タイル内部のPadding分のネガティブマージンを指定する 1:左右 2:上下左右
// $thumbnail-size: 書影サムネイルのサイズ（％）
@mixin adjustTile($list-max-width: 370, $column-num: 4, $grid-column-gap: 0, $grid-row-gap: 0, $tile-padding: 10, $is-negative-margin: 0, $thumbnail-size: 100, $is-thumb-padding-top: false) {

    // ネガティブマージンを付ける場合、ネガティブマージン分を外枠の最大サイズに足す
    @if $is-negative-margin == 1 or $is-negative-margin == 2 {
        $list-max-width: $list-max-width + $tile-padding * 2;
    }
    // グリッドの横幅
    $grid-width: floor(($list-max-width - ($grid-column-gap * ($column-num - 1))) / $column-num);
    // アスペクト比
    $book-aspect: 1.43;

    // 購入済みのグレー背景分がブロックからはみ出る感じにネガティブマージンを指定する
    @if $is-negative-margin == 1 {
        margin-left: $tile-padding * -1px;
        margin-right: $tile-padding * -1px;
    } @else if $is-negative-margin == 2 {
        margin: $tile-padding * -1px;
    }

    padding-top: $grid-row-gap * 1px;

    // タイル1つ分
    .o-tile, %o-tile {
        width: $grid-width * 1px;

        .o-tile-inner {
            padding: $tile-padding * 1px;
        }
    }

    .o-tile-list,
    .o-tile-list__slide {
        grid-template-columns: repeat(auto-fill, $grid-width * 1px);
        grid-column-gap: $grid-column-gap * 1px;
        grid-row-gap: $grid-row-gap * 1px;
    }

    // 書影
    .o-tile-book-info {
        .o-tile-book-img {
            .m-thumb-box {
                $def-height :floor(($grid-width - $tile-padding * 2) * $book-aspect);
                width: $thumbnail-size * 1%;

                @if $is-thumb-padding-top == true {
                    $padding-top: ($grid-width - $tile-padding * 2 - ($grid-width - $tile-padding * 2) * $thumbnail-size / 100) / 2;
                    padding-top: ($grid-width - $tile-padding * 2 - ($grid-width - $tile-padding * 2) * $thumbnail-size / 100) / 2 * 1px;
                    height: $def-height * $thumbnail-size / 100 + $padding-top * 1px ;
                } @else {
                    height: $def-height * $thumbnail-size / 100 * 1px;
                }

                img {
                    max-height: $def-height * $thumbnail-size / 100 * 1px;
                }
            }
        }
    }

    // シリーズ用タイル
    .o-series-tile-list {
        grid-template-columns: repeat(auto-fill, $grid-width * 1px);
    }

    // シリーズ一覧の中のタイル（上書き）
    .o-series-modal-tile-wrap {
        .o-series-modal-tile-list {
            grid-template-columns: repeat(auto-fill, $grid-width * 1px);
        }
    }

    // シリーズまとめの場合は下部のパディングを固定
    .o-tile--series,
    .o-tile--series--purchased {
        .o-tile-inner {
            padding-bottom: 4px;
        }
    }

    .o-tile--history, .o-tile--history--purchased {
        .o-tile-inner {
            padding: 10px $tile-padding * 1px;
        }
    }
}
/*

// レスポンシブタイル用
// -----------------------------------------------------------------------------
// タイル（ベースのスタイル：下部で調整用MIXINを呼ぶ）
// $list-max-width: 外枠の最大サイズ（px）
// $column-num: カラム数（px）
// $grid-column-gap: グリッドの左右間隔（px）
// $grid-row-gap: グリッドの上下間隔（px）
// $tile-top-bottom-padding: タイル内部のPadding-上下（px）
// $tile-left-right-padding: タイル内部のPadding-左右（px）
// $is-negative-margin: タイル内部のPadding分のネガティブマージンを指定する 1:左右 2:上下左右
// $thumbnail-size: 書影サムネイルのサイズ（％）
@mixin responsiveTile($list-max-width: 1080, $column-num: 5, $grid-column-gap: 0, $grid-row-gap: 0,  $tile-top-bottom-padding: 10, $tile-left-right-padding: 6,$is-negative-margin: 0, $thumbnail-size: 72) {
    // ネガティブマージンを付ける場合、ネガティブマージン分を外枠の最大サイズに足す
    @if $is-negative-margin == 1 or $is-negative-margin == 2 {
        $list-max-width: $list-max-width + $tile-left-right-padding * 2;
    }
    // グリッドの横幅
    $grid-width: floor(($list-max-width - ($grid-column-gap * ($column-num - 1))) / $column-num);
    // アスペクト比
    $book-aspect: 1.43;

    // 購入済みのグレー背景分がブロックからはみ出る感じにネガティブマージンを指定する
    @if $is-negative-margin == 1 {
        margin-left: $tile-left-right-padding * -1px;
        margin-right: $tile-left-right-padding * -1px;
    } @else if $is-negative-margin == 2 {
        margin: $tile-left-right-padding * -1px;
    }

    padding-top: $grid-row-gap * 1px;

    // タイル1つ分
    .o-tile, %o-tile {
        width: $grid-width * 1px;

        .o-tile-inner {
            padding:  $tile-top-bottom-padding * 1px $tile-left-right-padding * 1px;
        }
    }

    .o-tile-list,
    .o-tile-list__slide {
        display: grid;
        grid-template-columns: repeat(auto-fill, $grid-width * 1px);
        grid-column-gap: $grid-column-gap * 1px;
        grid-row-gap: $grid-row-gap * 1px;
        margin: 0;
    }

    .o-tile-list {
        justify-content: space-around;
    }

    // 横スクロールするタイプのタイルリスト（フォローリストより）----------
    .o-tile-list__slide {
        grid-auto-flow: column;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }

    // タイル1つ分
    .o-tile,
    .o-tile--purchased {
        display: flex;

        .o-tile-inner {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            // background: #fff;
            grid-auto-rows: min-content 1fr;
            justify-content: flex-start;
            word-break: break-all;
            outline: 1px dotted #fff;
        }
    }

    // 購入済み・登録済み
    .o-tile--purchased {
        .o-tile-inner {
            background-color: $purchased-color;
        }
    }

    // 書影
    .o-tile-book-info {
        .o-tile-book-img {
            position: relative;
            font-size: 0;
            text-align: center;

            .m-thumb-box {
                position: relative;
                width: 100%;
                height: auto;
                padding-top: $book-aspect * $thumbnail-size * 1%;

                // 書影画像
                .a-thumb-img {
                    width: $thumbnail-size * 1%;
                    height: 100%;
                    @include centering(x);
                    top: 0;
                    @include opacity-hover;

                    img {
                        display: inline-block;
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        object-fit: contain;
                        vertical-align: bottom;
                    }
                }

                // 個別削除ボタンのタップ範囲・位置調整
                .a-close-btn-box {
                    padding: 0 0 26px 26px;
                    top: -4px;
                    right: -3px;
                    @include opacity-hover;
                }

                // チェックボタン位置・タップ範囲調整
                .a-thumb-box__check {
                    right: -6px;
                    bottom: -8px;
                    @include opacity-hover;
                }
            }
        }

        // ジャンルタグ
        .o-tile-tag-box {
            width: 100%;
            padding: 7px 0 2px;
            font-size: 0;

            @include pc {
                padding-bottom: 4px;
            }

            // タグ本体
            span {
                &:not(:last-child) {
                    margin-right: 4px;

                    @include pc {
                        margin-right: 3px;
                    }
                }
            }
        }

        // 作品タイトル
        .o-tile-ttl {
            font-size: 12px;
            font-weight: 700;
            line-height: 1.2;
            max-height: 2.75rem;
            white-space: normal;
            overflow: hidden;
            word-break: break-all;
            @include line-clamp(2);

            // iOSでline-clampの表示対策（何かで囲っていないと3点リーダが中途半端な位置に出る）
            :first-child {
                &::before {
                    content: ""
                }

                &::after {
                    content: "";
                }
            }

            a {
                @include hover-pc {
                    opacity: 1;
                    text-decoration: underline;
                }
            }
        }

        // 著者名
        .o-tile-book-author {
            color: $weak-gray;
            font-size: 10px;
            line-height: 1.2;
            padding: 3px 0 1px;

            @include pc {
                padding-top: 4px;
            }
        }
    }

    // タイル内下半分のエリア----------
    .o-tile-under-box {

        // 登録状況タグ
        .o-tile-status-tag {
            padding: 2px 0 1px 0;
            text-align: right;
        }

        // 価格周り
        .o-tile-price {
            padding: 0 1px 1px 0;
            font-size: 11px;
            color: $strong-red;
            text-align: right;

            @include pc {
                font-size: 13px;
                font-weight: $font-bold;
            }

            // テキストサイズ・色 微調整
            .a-tile-period {
                display: block;
                font-size: 10px;
            }

            .a-tile-schedule {
                display: block;
                font-size: 10px;
                color: $strong-blue;
            }

            .a-tile-price--yen {
                font-size: 10px;
                font-weight: normal;
            }

            .a-tile-price--range {
                color: $base-text-color;
                font-size: 10px;
                font-weight: normal;
            }

            .a-tile-price--tax {
                display: inline-block;
                color: $base-text-color;
                font-size: 10px;
                font-weight: normal;
            }
        }

        .o-tile-count {
            padding: 3px 0 1px;
            font-size: 10px;
            line-height: 1.2;
            text-align: right;
        }

        // ボタンブロック：可変対応
        .m-btn-box {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            grid-column-gap: 4px;
            width: 100%;
            min-height: 42px;
            padding: 1px 1px;

            li {
                margin: 0;

                a {
                    width: 100%;
                    margin: 0;
                }
            }

            .a-purchase-AppleID-btn, .a-read-by-app-btn {
                margin: 0 2px 0 0;
            }
        }
    }

    // レスポンシブスライダー　swiper（カート画面の棚）----------
    .o-tile-list--slider {
        display: flex;

        .swiper-slide {
            &:not(:last-child) {
                @include pc {
                    margin-right: 8px;
                }
            }
        }

        .o-tile,
        .o-tile--purchased {
            width: 100%;
            height: 100%;
            box-sizing: content-box;
        }

        // スライダーボタン
        & + .a-slider-btn__prev {
            // SPでボタン非表示
            @include utab {
                display: none;
            }

            & + .a-slider-btn__next {
                // SPでボタン非表示
                @include utab {
                    display: none;
                }
            }
        }

        .swiper-slide {
            height: auto;
        }
    }

    // タイル調整

}

@mixin responsiveAdjustTile($list-max-width: 370, $column-num: 4, $grid-column-gap: 0, $grid-row-gap: 0, $tile-padding: 10, $is-negative-margin: 0, $thumbnail-size: 100, $is-thumb-padding-top: false) {

    // ネガティブマージンを付ける場合、ネガティブマージン分を外枠の最大サイズに足す
    @if $is-negative-margin == 1 or $is-negative-margin == 2 {
        $list-max-width: $list-max-width + $tile-padding * 2;
    }
    // グリッドの横幅
    $grid-width: floor(($list-max-width - ($grid-column-gap * ($column-num - 1))) / $column-num);
    // アスペクト比
    $book-aspect: 1.43;

    // 購入済みのグレー背景分がブロックからはみ出る感じにネガティブマージンを指定する
    @if $is-negative-margin == 1 {
        margin-left: $tile-padding * -1px;
        margin-right: $tile-padding * -1px;
    } @else if $is-negative-margin == 2 {
        margin: $tile-padding * -1px;
    }

    padding-top: $grid-row-gap * 1px;

    // タイル1つ分
    .o-tile, %o-tile {
        width: $grid-width * 1px;

        .o-tile-inner {
            padding: $tile-padding * 1px;
        }
    }

    .o-tile-list,
    .o-tile-list__slide {
        grid-template-columns: repeat(auto-fill, $grid-width * 1px);
        grid-column-gap: $grid-column-gap * 1px;
        grid-row-gap: $grid-row-gap * 1px;
    }

    // 書影
    .o-tile-book-info {
        .o-tile-book-img {
            .m-thumb-box {
                $def-height :floor(($grid-width - $tile-padding * 2) * $book-aspect);
                width: $thumbnail-size * 1%;

                @if $is-thumb-padding-top == true {
                    $padding-top: ($grid-width - $tile-padding * 2 - ($grid-width - $tile-padding * 2) * $thumbnail-size / 100) / 2;
                    padding-top: ($grid-width - $tile-padding * 2 - ($grid-width - $tile-padding * 2) * $thumbnail-size / 100) / 2 * 1px;
                    height: $def-height * $thumbnail-size / 100 + $padding-top * 1px ;
                } @else {
                    height: $def-height * $thumbnail-size / 100 * 1px;
                }

                img {
                    max-height: $def-height * $thumbnail-size / 100 * 1px;
                }
            }
        }
    }

    // シリーズ用タイル
    .o-series-tile-list {
        grid-template-columns: repeat(auto-fill, $grid-width * 1px);
    }

    // シリーズ一覧の中のタイル（上書き）
    .o-series-modal-tile-wrap {
        .o-series-modal-tile-list {
            grid-template-columns: repeat(auto-fill, $grid-width * 1px);
        }
    }

    // シリーズまとめの場合は下部のパディングを固定
    .o-tile--series,
    .o-tile--series--purchased {
        .o-tile-inner {
            padding-bottom: 4px;
        }
    }

    .o-tile--history, .o-tile--history--purchased {
        .o-tile-inner {
            padding: 10px $tile-padding * 1px;
        }
    }
}

*/
