// 主としてカード表示で使うmolecules
@import "../atoms/colors";


// ボタン周り
.m-btn-box {
    clear: both;
    float: right;
    overflow: hidden;
    margin: 2px auto;
    box-sizing: border-box;

    li {
        float: left;
        overflow: hidden;
        margin: 0 4px 0 0;
        list-style: none;

        &:last-child {
            margin: 0;
        }
    }
}