/**
  TOP横断バナー(SP)
 */
.o-top-campaign-banner-list {
    width: 100%;
    height: auto;
}

.o-top-campaign-banner {
    text-align:center;
    margin-bottom: 8px;

    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }

    a {
        display: block;
    }
}
