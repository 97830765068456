@charset "utf-8";

// ============================
// 読み込み
// ============================
@import "./tile-mixin";
@import "./responsive-tile-mixin";
@import '../../../pc/atoms/buttons/slider-button';
@import '../../organisms/tile/tile-title-overlay';

// ============================
// 各タイルパターン
// ============================

// リストページ（4カラム表示）
// ============================
.o-tile-wrap {
    @include tile(370, 4, 0, 5, 3, 0, 94);

    // アンドロイド等（w360px）でも4列に表示できるように調整する
    @media (max-width: (374px)) {
        @include adjustTile(356, 4, 0, 5, 2, 0, 94);

        .m-btn-box li {
            margin-right: 0;
        }
    }

    // iPhone SE等（w320px）は3列で表示するように調整する
    @media (max-width: (320px)) {
        @include adjustTile(316, 3, 0, 5, 2, 0, 94);

        .m-btn-box li {
            margin-right: 0;
        }

        // タイトル全表示オーバーレイ
        .o-tile-overlay--on {
            height: calc(100% - 121px);
        }

        // タイトル全表示のオーバーレイが、単品用のでは長すぎるので短くする
        .o-tile--series .o-tile-overlay--on,
        .o-tile--series--purchased .o-tile-overlay--on {
            height: calc(100% - 77px);
        }
    }

    overflow: hidden;

    .o-tile,
    .o-tile--purchased,
    .o-tile--series,
    .o-tile--series--purchased,
    .o-tile--history,
    .o-tile--history--purchased {
        border-bottom: 1px dotted $border-color;
        border-collapse: separate;
    }
}

// 3カラム版（館トップ等）
// ============================
.o-tile-wrap--3column {
    @include tile(343, 3, 8, 8, 6, 1);

    // アンドロイド等（w360px）でも3列に表示できるように調整する
    @media (max-width: (374px)) {
        @include adjustTile(328, 3, 8, 8, 6, 1);
    }

    // iPhone SE等（w320px）でも3列に表示できるように調整する
    @media (max-width: (320px)) {
        @include adjustTile(288, 3, 8, 8, 6, 1);
    }

    // タイトルを一行で省略
    .o-tile-book-info .o-tile-ttl {
        -webkit-line-clamp: 1;
    }
}

// 5カラム・レスポンシブ版（selectページ等）
// タイルの下のborderは非表示
// ============================
.o-tile-wrap--5column {
    @include responsiveTile(1040, 5, 0, 8, 8, 0, 80, 20, true);

    // 書影の高さが可変になる
    @media screen and (max-width: 1080px) {
        @include responsiveAdjustTile(1040, 5, 0, 8, 8, 0, 80, 20, false);
    }

    @media screen and (max-width: 700px) {
        @include responsiveAdjustTile(355, 3, 0, 8, 5, 0, 80, 40, true);
    }

    .o-tile-list,
    .o-tile-list__slide {
        padding: 0;
        margin: 0;

        @media screen and (min-width: 701px) {
            grid-template-columns: repeat(auto-fit, 20%);
        }

        .o-tile,
        .o-tile--purchased,
        .o-tile--series,
        .o-tile--series--purchased {
            border: 0;

            // 読むボタンを上に表示する、ボタン幅100%
            .o-tile-under-box {
                .m-btn-box {
                    min-height: 42px;
                    display: flex;
                    flex-direction: column-reverse;

                    li {
                        float: none;

                        &:not(:last-child) {
                            margin-top: 8px;
                        }

                        a {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .o-tile,
    .o-tile--purchased,
    .o-tile--series,
    .o-tile--series--purchased {
        @media screen and (min-width: 701px) {
            width: 100%;
            height: 100%;
            box-sizing: content-box;
        }
    }

    // SPでスライドの場合（selectページランキング棚等）
    .o-tile-list__slide {
        justify-content: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, 207px);
        margin: 0 -20px;
        padding: 0 20px;
        @include responsiveTile(1040, 5, 0, 8, 8, 0, 80, 20, true);

        @media screen and (max-width: 700px) {
            grid-template-columns: repeat(auto-fit, 117px);
            @include responsiveAdjustTile(355, 3, 0, 8, 5, 0, 80, 40, true);
            margin: 0 -10px;
            padding: 0 10px;
        }

        .o-tile,
        .o-tile--purchased,
        .o-tile--series,
        .o-tile--series--purchased {
            width: 207px;

            @media screen and (max-width: 700px) {
                width: 117px;
            }
        }
    }

    // ランキング
    .m-book-ranking {
        text-align: center;

        .a-rank-first,
        .a-rank-second,
        .a-rank-third,
        .a-rank-other {
            @include pc {
                font-size: 13px;
            }

            .rank-txt-s {
                @include pc {
                    font-size: 13px;
                }
            }
        }

        .ico-crown {
            font-size: 10px;
            padding-right: 2px;

            @include pc {
                font-size: 13px;
            }
        }
    }
}

// サムネ大きく：表示スタイル
// ============================
.o-tile-wrap--large {
    @include tile(370, 3, 0, 5, 5, 0);

    // アンドロイド等（w360px）でも3列に表示できるように調整する
    @media (max-width: (374px)) {
        @include adjustTile(355, 3, 0, 5, 5, 0);
    }

    .o-tile,
    .o-tile--purchased,
    .o-tile--series,
    .o-tile--series--purchased {
        border-bottom: 1px dotted $border-color;
        border-collapse: separate;
    }

    // タイトル全表示オーバーレイ
    .o-tile-overlay--on {
        height: calc(100% - 134px);
    }

    // タイトル全表示のオーバーレイが、単品用のでは長すぎるので短くする
    .o-tile--series .o-tile-overlay--on,
    .o-tile--series--purchased .o-tile-overlay--on {
        height: calc(100% - 86px);
    }

    // iPhone SE等（w320px）
    @media (max-width: (320px)) {
        @include adjustTile(316, 2, 0, 5, 10, 0);

        // タイトル全表示オーバーレイ
        .o-tile-overlay--on {
            height: calc(100% - 158px);
        }

        // タイトル全表示のオーバーレイが、単品用のでは長すぎるので短くする
        .o-tile--series .o-tile-overlay--on,
        .o-tile--series--purchased .o-tile-overlay--on {
            height: calc(100% - 106px);
        }
    }
}

// 余白を広く：表示スタイル
// ============================
.o-tile-wrap--space {
    @include tile(370, 3, 0, 5, 4, 0, 75);

    // アンドロイド等（w360px）でも3列に表示できるように調整する
    @media (max-width: (374px)) {
        @include adjustTile(355, 3, 0, 5, 5, 0, 75);
    }

    .o-tile,
    .o-tile--purchased,
    .o-tile--series,
    .o-tile--series--purchased {
        border-bottom: 1px dotted $border-color;
        border-collapse: separate;
    }

    // タイトル全表示オーバーレイ
    .o-tile-overlay--on {
        height: calc(100% - 134px);
    }

    // タイトル全表示のオーバーレイが、単品用のでは長すぎるので短くする
    .o-tile--series .o-tile-overlay--on,
    .o-tile--series--purchased .o-tile-overlay--on {
        height: calc(100% - 86px);
    }

    // iPhone SE等（w320px）
    @media (max-width: (320px)) {
        @include adjustTile(316, 2, 0, 0, 10, 0, 75);

        // タイトル全表示オーバーレイ
        .o-tile-overlay--on {
            height: calc(100% - 158px);
        }

        // タイトル全表示のオーバーレイが、単品用のでは長すぎるので短くする
        .o-tile--series .o-tile-overlay--on,
        .o-tile--series--purchased .o-tile-overlay--on {
            height: calc(100% - 106px);
        }
    }
}

// 棚
// ============================
.o-tile-wrap--shelf {
    @include tile(360, 4, 2, 0, 5, 1);

    // タイトルを一行で省略
    .o-tile-book-info .o-tile-ttl {
        -webkit-line-clamp: 1;
    }
}

// 棚(スライド用の設定追加)
// ============================
.o-tile-wrap--slide-shelf {
    @include tile(360, 4, 2, 0, 5, 1);

    // タイトルを一行で省略
    .o-tile-book-info .o-tile-ttl {
        -webkit-line-clamp: 1;
    }

    // TODO 親要素のpaddingをとりあえず固定でここで定義
    $section-side-padding: 16;

    margin-left: $section-side-padding * -1px;
    margin-right: $section-side-padding * -1px;
    overflow-y: hidden;
    overflow-x: scroll;

    .o-tile-list__slide {
        overflow-x: visible;
        overflow-y: visible;
        display: inline-flex;
        padding-right: $section-side-padding * 1px;
        padding-left: $section-side-padding * 1px;
    }
}

// 棚（レスポンシブ用）
// ============================
.o-tile-wrap--swiper-shelf {
    @include tile(815, 5, 0, 0, 8, 8, 72);
    margin: 0;
    padding: 0 16px 16px;
    overflow-x: scroll;
    overflow-y: hidden;

    // タブレット
    @include utab {
        @include adjustTile(343, 3, 0, 5, 5, 1);
        margin: 0;
        padding: 0 16px 16px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    // アンドロイド等（w360px）でも3列に表示できるように調整する
    @media (max-width: (374px)) {
        @include adjustTile(328, 3, 0, 5, 5, 1);
    }

    // iPhone SE等（w320px）でも3列に表示できるように調整する
    @media (max-width: (320px)) {
        @include adjustTile(288, 3, 0, 5, 5, 1);
    }
    .swiper-slide {
        width: 163px;

        // タブレット
        @include utab {
            width: 113px;
        }

        // アンドロイド等（w360px）でも3列に表示できるように調整する
        @media (max-width: (374px)) {
            width: 112px;
        }

        // iPhone SE等（w320px）でも3列に表示できるように調整する
        @media (max-width: (320px)) {
            width: 99px;
        }
    }
}

// ランキング
// ============================
.o-tile-wrap--ranking {
    @include tile(343, 3, 8, 8, 6, 1);

    // アンドロイド等（w360px）でも3列に表示できるように調整する
    @media (max-width: (374px)) {
        @include adjustTile(328, 3, 8, 8, 6, 1);
    }

    // iPhone SE等（w320px）でも3列に表示できるように調整する
    @media (max-width: (320px)) {
        @include adjustTile(288, 3, 8, 8, 6, 1);
    }

    .o-tile-list {
        .o-tile,
        .o-tile--purchased {
            flex-direction: column;
        }
    }
    // タイトルを一行で省略
    .o-tile-book-info .o-tile-ttl {
        -webkit-line-clamp: 1;
    }
}

// 順位
.o-tile-ranking {
    padding: 0 6px;
}