//館TOPバナー

@import "../../molecules/swiper";

// スライドあり
.o-store-top-banner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

//スライドしない（バナー1枚以下）
.o-store-top-banner--no-slide {
    max-width: 450px;
    animation: load-banner-fadeIn 0.7s linear;
    margin: 0 auto 8px;
    text-align: center;

    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }

    a {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}