@charset "utf-8";

// スライドバナー（Swiper）
//（Swiperオリジナルのスタイルを上書き）

@import "../atoms/colors";
@import "../atoms/buttons/swiper-button";

//タテスクのbaseTransitionと同じ
@mixin baseTransition {
    transition: all 0.3s ease-in-out;
}

$bullet-size: 6px;
$pagination-top-padding: 8px;
$pagination-bottom-padding: 8px;
$break-point-up: 767.98px;
$pagination-height: $pagination-top-padding + $bullet-size + $pagination-bottom-padding;

// カスタムスタイル
.m-swiper {
    position: relative;
    width: 100%;
    background-color: #EEF0F2;
    margin: 0 auto;

    .swiper-container {
        width: 100%;
        height: 100%;
        opacity: 0;
        overflow: visible;
        padding-bottom: $pagination-height;
    }

    .swiper-container-initialized {
        opacity: 1;
        transition: opacity 1s ease;
    }

    .swiper-wrapper {
        min-height: calc(100vw * ( 133/375 ));
        @media screen and (min-width: $break-point-up) {
            min-height: 200px;
        }
    }

    li.swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        background-color: #f8f8f8;

        @media screen and (min-width: $break-point-up) {
            max-width: 450px;
            max-height: 200px;
            margin: 0 auto;
        }

        > div {
            height: 100%;
        }

        a {
            display: flex;
            align-items: center;
            height: 100%;
        }

        img {
            max-width: 100%;
            height: auto;
            vertical-align: middle;
        }

        // 遅延画像
        .swiper-lazy:not(.swiper-lazy-loaded) {
            display: none;
        }
    }

    // Preloader上書き
    .swiper-lazy-preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: none;
        background: none;
        border: none;
        border-radius: unset;
        margin: 0;
        z-index: unset;
    }
    .swiper-lazy-preloader:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: none;
    }

    //　pagination
    .m-swiper-pagination {
        left: 0;
        max-height: $pagination-height;
        bottom: 0;

        .swiper-pagination-bullet {
            position: relative;
            width: $bullet-size + 2px;
            height: $bullet-size + 2px;
            opacity: 1;
            background-color: transparent;
            box-sizing: content-box;
            margin: 0 2px;
            padding: $pagination-top-padding 2px;
            @include baseTransition;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: $bullet-size;
                height: $bullet-size;
                background-color: #ccc;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                @include baseTransition;
            }
        }

        .swiper-pagination-bullet-active {
            width: 16px;
            @include baseTransition;

            &::before {
                width: 16px;
                height: $bullet-size + 2px;
                border-radius: 100vh;
                @include baseTransition;
            }
        }
    }

    .m-swiper-pagination--sougou {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color: $base-color;
            }
        }
    }

    .m-swiper-pagination--lightnovels {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color: $light-novel-key-color;
            }
        }
    }

    .m-swiper-pagination--manga {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color: $manga-key-color;
            }
        }
    }

    .m-swiper-pagination--literature {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color:  $literature-business-key-color;
            }
        }
    }

    .m-swiper-pagination--bl {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color:  $bl-key-color;
            }
        }
    }

    .m-swiper-pagination--tl {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color: $tl-key-color;
            }
        }
    }

    .m-swiper-pagination--indies {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color: $indies-key-color;
            }
        }
    }

    .m-swiper-pagination--free {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color: $free-key-color;
            }
        }
    }

    .m-swiper-pagination--otona {
        >.swiper-pagination-bullet-active {
            &::before {
                background-color: $r18-key-color;
            }
        }
    }

    .a-swiper-button--prev,
    .a-swiper-button--next {
        top: calc((100% - #{$pagination-height}) / 2);
        transform: translateY(-50%);
    }
}

@keyframes load-banner-fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
