// ボタン

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

// 送りボタン（デフォルト）
// 矢印
.a-slider-btn__arrow--prev {
    display: block;
    position: relative;
    width: 32px;
    height: 64px;
    border-radius: 0 6px 6px 0;
    background: rgba(29,56,80,.7);
    z-index: 1;

    &:hover {
        cursor: pointer;

        .a-slider-btn__arrow--prev,
        .a-slider-btn__arrow--next {
            background: rgba(29,56,80,1);
        }
    }

    &::after {
        content: "";
        display: block;
        position: relative;
        width: 10px;
        height: 10px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        @include centering(xy, -135);
    }
}
.a-slider-btn__arrow--next {
    @extend .a-slider-btn__arrow--prev;
    border-radius: 6px 0 0 6px;

    &::after {
        @include centering(xy, 45);
    }
}
// プレビューボタン
.a-slider-btn__prev {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 100%;
    background: linear-gradient(to right,#ffffff 0,rgba(255,255,255,0) 100%);

    &:hover {
        cursor: pointer;

        .a-slider-btn__arrow--prev,
        .a-slider-btn__arrow--next {
            background: rgba(29,56,80,1);
        }
    }

    &.swiper-button-disabled {
        visibility: hidden;
    }
}
// ネクストボタン
.a-slider-btn__next {
    @extend .a-slider-btn__prev;
    left: unset;
    right: 0;
    background: linear-gradient(to left,#ffffff 0,rgba(255,255,255,0) 100%);
}

// 送りボタン（染み出し用）
// 矢印
.a-preview-slider-btn__arrow--next {
    display: block;
    position: relative;
    width: 32px;
    height: 64px;
    border-radius: 0 6px 6px 0;
    background: rgba(29,56,80,.7);
    z-index: 1;

    &::after {
        content: "";
        display: block;
        position: relative;
        width: 10px;
        height: 10px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        @include centering(xy, -135);
    }
}
.a-preview-slider-btn__arrow--prev {
    @extend .a-preview-slider-btn__arrow--next;
}
// ネクストボタン
.a-preview-slider-btn__next, %a-preview-slider-btn__next {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 100%;
    background: linear-gradient(to right,#ffffff 0,rgba(255,255,255,0) 100%);

    &:hover {
        cursor: pointer;

        .a-preview-slider-btn__arrow--prev,
        .a-preview-slider-btn__arrow--next {
            background: rgba(29,56,80,1);
        }
    }

    &.swiper-button-disabled {
        visibility: hidden;
    }
}
// プレビューボタン
.a-preview-slider-btn__prev {
    @extend %a-preview-slider-btn__next;
    left: unset;
    right: 0;
    @include centering(y, 180);
    background: linear-gradient(to right,#ffffff 0,rgba(255,255,255,0) 100%);

    &.swiper-button-disabled {
        visibility: hidden;
    }
}
// ネクストボタン
.a-slider-btn__prev,
.a-slider-btn__next,
.a-preview-slider-btn__prev,
.a-preview-slider-btn__next {
    z-index: 100;
}