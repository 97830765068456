// 書影やカード関連の共通パーツを置く
@import "colors";
@import '../templates/layout/mixin';

// 評価用★表示
.a-rating-starts {
    display: inline-block;

    li {
        margin: -1px;
        font-size: 13px;
        color: $stars-color;
        // 自分の星は色を変える
        &.ico-my-star {
            color: $my-stars-color;
        }
    }
}

.a-rating-starts_text {
    color: #999;
    display: inline-block;
    font-size: 1rem;
}

// 表紙画像
.a-thumb-img {

    img {
        display: block;
        max-width: 120px;
        width: 100%;
        //max-height: 168px;
        max-height: 172px;
        outline: 1px solid #ccc;
        @include se {
            max-width: 90px;
        }
    }
}
