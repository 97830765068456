@charset "UTF-8";

// セクション（ブロックのデザイン）
@import "../../atoms/colors";
@import "../../atoms/arrow-mixin";
@import "../../../pc/templates/layout/define";

// コンテンツのセクション（館トップ等）
.o-contents-section, %o-contents-section {
    position: relative;
    margin-bottom: 16px;
    padding: 13px 16px;
    border-top: 2px solid $base-color;
    background-color: #ffffff;

    &:last-child {
        margin-bottom: 0;
    }

    .a-more-btn--all {
        margin-top: 22px;
    }
}

// ヘッダー部分
.o-contents-section-header {
    display: flex;
    margin-bottom: 13px;

    // もっと見るボタン
    .a-more-btn,
    .a-more-btn--display-toggle {
        flex-shrink: 0;
        margin-top: -3px;
        margin-right: -6px;
    }
}

// タイトル
.o-contents-section-ttl,
%o-contents-section-ttl {
    position: relative;
    padding: 0.2em 1.5em 0.2em 8px;
    border-left: 4px solid $base-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.3;
    color: $base-color;
    flex-basis: 100%;

    // タイトル横ヘルプアイコン
    .ico-help {
        position: absolute;
        padding: 0 0 0 4px;
        font-size: 13px;
        vertical-align: super;
    }
}

// タイトル：重要なお知らせ
.o-contents-section-ttl--info {
    @extend %o-contents-section-ttl;

    padding-left: 24px;
    border-left: 0;

    .ico {
        font-size: 16px;
        @include centering(y);
        left: 0;
    }
}

// お知らせのリスト（通常）
.o-info-list, %o-info-list {

    li {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            display: block;
            position: relative;
            border: 1px solid $border-color;
            border-radius: 4px;
            font-size: 11px;
            padding: 7px 25px 7px 7px;
            @include hover;

            // 矢印
            &::after {
                @include right-arrow($weak-gray);
                right: 10px;
                width: 10px;
                height: 10px;
            }
        }
    }
}

// お知らせのリスト（重要）
.o-info-list--important {
    @extend %o-info-list;

    display: block;
    margin-bottom: 8px;
}

.o-info-list--top {
    @extend %o-info-list;

    display: block;
    margin-bottom: 8px;
}
