// TODO 親要素のpaddingをとりあえず固定でここで定義
$section-side-padding: 16;

// 人気の電子書籍
.m-popular-book-list-wrap {
    // 横スクロールの棚は親要素を無視してフルワイドに
    margin: 0 $section-side-padding * -1px;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    height: 80px;
}

.m-popular-book-list {
    // スクロール左右の余白
    padding: 0 $section-side-padding * 1px;
    position: absolute;
    display: grid;
    grid-template-rows: repeat(auto-fit, 38px);
    grid-template-columns: repeat(auto-fit, 80px);;
    grid-auto-flow: column;
    grid-gap: 4px 8px;
    height: 38*2 + 4px;

    // アイテム単体（レーベルロゴ）
    li {
        width: 80px;

        a {
            display: block;
        }

        img {
            width: 100%;
            height: 38px;
            object-fit: contain;
            vertical-align: bottom;
        }
    }
}