// ブロック型（複数の要素を内包するもの・バナーに近いもの）リンクのリスト

@import "../atoms/colors";
@import "../templates/layout/mixin";

// その他のジャンル
.o-other-genre-link-list {
    li {
        margin-bottom: 14px;
        height: 84px;
        border: 1px solid $border-color;
        border-radius: 4px;

        &:last-of-type {
            margin-bottom: 0;
        }

        // BL
        &.o-other-genre-link-list__item--bl {
            a {
                border-color: #ff6ed8;
                background-color: #ffc5dd;
                color: #a11c6f;

                &::before {
                    background-color: #ff98dc;
                }

                &::after {
                    border-color: #a11c6f;
                }
            }
        }

        // TL
        &.o-other-genre-link-list__item--tl {
            a {
                border-color: #b481d8;
                background-color: #e8caff;
                color: #65349c;

                &::before {
                    background-color: #c793f0;
                }

                &::after {
                    border-color: #65349c;
                }
            }
        }

        // 同人誌・個人出版
        &.o-other-genre-link-list__item--indies {
            a {
                border-color: #5ab5bc;
                background-color: #b3ecf0;
                color: #145470;

                &::before {
                    background-color: #81d9df;
                }

                &::after {
                    border-color: #145470;
                }
            }
        }

        a {
            display: flex;
            padding-right: 28px;
            position: relative;
            height: 100%;
            border-left: 12px solid #cccccc;
            align-content: center;
            align-items: center;

            // 左の装飾の上半分
            &::before {
                content: "";
                display: block;
                position: absolute;
                left: -12px;
                top: 0;
                width: 12px;
                height: 50%;
                background-color: #ffffff;
            }

            // 右の矢印
            &::after {
                content: "";
                display: block;
                @include centering(y, 45);
                right: 16px;
                width: 10px;
                height: 10px;
                border-top: 2px solid #ffffff;
                border-right: 2px solid #ffffff;
            }

            .o-other-genre-link-list__item__cover {
                flex-shrink: 0;
                margin-left: 12px;
                margin-right: 12px;
                padding: 5px 0;
                height: 100%;
                font-size: 0;

                @include se {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                img {
                    max-height: 100%;

                    &:first-of-type {
                        margin-right: 6px;
                    }
                }
            }

            .o-other-genre-link-list__item__txt {
                font-size: 18px;
                font-weight: bold;
                line-height: 1;

                > .nowrap {
                    white-space: nowrap;
                }

                small {
                    font-size: 0.8em;
                    font-weight: normal;
                }
            }
        }
    }
}