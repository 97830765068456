// 主としてカード表示のatoms
@import "colors";
@import "../templates/layout/font-variable";

// 著者名
.a-card-author {
    display: inline;
    font-size: 1rem;
    color: $base-text-color;

    a {
        &:not(:first-of-type){
            position: relative;
            padding-left: 10px;
            &::before {
                content: '｜';
                position: absolute;
                left: 0;
                top: 1px;
            }
        }
    }
}
.a-card-author-type {
    display: inline;
    margin-right: 2px;
    font-size: 1rem;
    color: $weak-gray;
}

// 出版社レーベル
.a-card-book-label {
    font-size: 1rem;
    color: $weak-gray;
}

//出版社名
.a-card-publisher {
    @extend .a-card-book-label;
}


// キャンペーン期間
.a-card-period {
    font-size: 1rem;
    color: $strong-red;

    span[class^="a-tag"] {
        margin-right: 4px;
    }
}

// 配信予定日
.a-card-schedule {
    font-size: 1rem;
    color: $strong-blue;
    margin-right: 0;
    span[class^="a-tag"] {
        margin-right: 4px;
    }
}
// Androidアプリ用注意文言
.a-card-trial-alert {
    font-size: 1rem;
    color: $strong-red;
    margin-right: 0;
    span[class^="a-tag"] {
        margin-right: 4px;
    }
}

// 発売日（配信予定状態除いた発売後）
.a-card-release {
    font-size: 1rem;
    color: $weak-gray;
    span[class^="a-tag"] {
        margin-right: 4px;
    }
}

// 価格
.a-card-price {
    font-size: 1.3rem;
    font-weight: $font-bold;
    color: $strong-red;
}

.a-card-price--yen {
    font-size: 1rem;
    font-weight: normal;
}

.a-card-price--tax {
    font-size: 1rem;
    font-weight: normal;
    color: $base-text-color;
}

.a-card-price--sbsc {
    @extend .a-card-price;
    font-size: 13px;
}

// 注意文言（右寄せ）
.a-card-text--notice {
    font-size: 10px;
    color: $base-text-color;
    line-height: 1.2;
    margin-top: 2px;
    text-align: right;

    a {
        text-decoration: underline;
    }
}

// 注意文言（左寄せ）
.a-card-text--notice-left {
    @extend .a-card-text--notice;
    text-align: left;
    margin-left: auto;
}

// 注意文言（決済）
.a-card-text--notice-payment {
    @extend .a-card-text--notice;
    margin: 2px 0;
    text-align: right;
}